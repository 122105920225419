<template>
  <vxe-pulldown ref="xDown" transfer style="width: 100%">
    <template #default>
      <el-tooltip placement="top-start" :content="fullName" effect="light" :enterable="false" :open-delay="500">
        <vxe-input v-model="name" style="width: 100%;" size="medium" :suffix-icon="!readonly?'vxe-icon-search':''" :placeholder="placeholder" @click="showDropdown" readonly></vxe-input>
      </el-tooltip>
    </template>
    <template #dropdown>
      <vxe-table
          max-height="350"
          show-overflow
          :row-config="{isHover: true,isCurrent: true,useKey: true}"
          :column-config="{resizable: true}"
          :tree-config="treeConfig"
          :data="tableData"
          size="mini"
          @cell-click="cellClickEvent"
          :scroll-y="{enabled: false}">
        <vxe-column field="name" title="目录名称" tree-node>
          <template v-slot="{ row }">
            <span :class="{'name-disabled':row.id == disabledId}">{{ row.name + '(' + row.code + ')' }}</span>
          </template>
        </vxe-column>
      </vxe-table>
    </template>
  </vxe-pulldown>
</template>
<script>
export default {
  data() {
    return {
      treeConfig: {
        transform: true,
        rowField: 'id',
        parentField: 'pid',
        showLine: true,
        iconOpen: 'vxe-icon-square-minus',
        iconClose: 'vxe-icon-square-plus',
        toggleMethod: this.toggleMethod
      },
      tableData: [],
      name: '无',
      dataLoading: false,
      fullName: '无',
      // 记录value的值，用于在watch value的值时判断是由于组件外部的修改（需要回显） 或是组件内部选择了某选项，双向绑定后导致的value变化（不需处理）
      localValue: '',
    }
  },
  created: function () {
    this.getInfo()
  },
  props: {
    value: [String, Number],
    placeholder: String,
    readonly: Boolean,
    // 默认只显示节点名称,传入true则显示完整路径，
    showPNames: {
      type: Boolean,
      default: false
    },
    // 此id对应的节点及其子树不可选中
    disabledId: {
      type: [String, Number],
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  watch: {
    // 数据回显
    value: function () {
      this.getInfo()
    }
  },
  methods: {
    showDropdown() {
      if (!this.readonly) {
        if (!this.tableData || this.tableData.length == 0) {
          this.getDataList()
        }
        this.$refs.xDown.showPanel()
      }
    },
    cellClickEvent({row, triggerTreeNode}) {
      // 如果是折叠/展开树的操作，就不触发点击事件
      if (triggerTreeNode) {
        return
      }
      // 如果是被禁用的节点，则不允许选中
      if (this.disabledId && this.disabledId == row.id) {
        return
      }
      this.showName(row)
      this.localValue = row.id
      this.$emit('change', row.id)
      this.$refs.xDown.hidePanel()
    },
    getInfo() {
      // 因组件内部选择了某选项，双向绑定后导致的value变化，所以不作处理
      if (this.value === this.localValue) {
        return
      }
      this.localValue = this.value
      if (this.value == 0) {
        this.name = '无'
        this.fullName = '无'
        return
      }
      if (this.value) {
        this.$http.get('/mps/archiveOutline/' + this.value).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.showName(res.data)
        }).catch(() => {
          // do nothing
        })
      }
    },
    showName(data) {
      if (data) {
        let pnames = data.pnames ? (data.pnames + ' / ' + data.name) : data.name
        if (this.showPNames) {
          this.name = pnames
        } else {
          this.name = data.name + '(' + data.code + ')'
        }
        this.fullName = pnames
      }
    },
    toggleMethod({row}) {
      // 如果是被禁用的节点，则不允许展开
      if (this.disabledId && this.disabledId == row.id) {
        return false
      } else {
        return true
      }
    },
    getDataList() {
      if (!this.dataLoading) {
        this.dataLoading = true
        this.$http.get('/mps/archiveOutline/list').then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.tableData = res.data
        }).catch(() => {
          // do nothing
        }).finally(() => {
          this.dataLoading = false
        })
      }
    }
  },
}
</script>
<style scoped>
.name-disabled {
  color: rgba(128, 128, 128, 0.54)
}
</style>
