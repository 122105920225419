<template>
  <el-dialog v-dialog-drag :visible.sync="visible" :title="!dataForm.id ? '新增 卷内文件': '修改 卷内文件'" top="5vh" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-divider content-position="left">基本信息</el-divider>
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="文件题名" prop="name">
            <el-input v-model="dataForm.name" placeholder="文件题名"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="图文编号" prop="code">
            <el-input v-model="dataForm.code" placeholder="图文编号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="责任者" prop="liable">
            <el-input v-model="dataForm.liable" placeholder="责任者"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="页次" prop="pages">
            <el-input v-model="dataForm.pages" placeholder="页次"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="文件形成日期" prop="fileDate">
            <el-date-picker type="date" placeholder="文件形成日期" value-format="yyyy-MM-dd" v-model="dataForm.fileDate" style="width: 100%;" :clearable="!dataForm.id"></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-divider content-position="left">著录信息</el-divider>
    <archive-meta-viewer :dataList="dataForm.metaValList" :readonly="false"></archive-meta-viewer>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'
import ArchiveMetaViewer from "@/components/archive-meta-viewer"

export default {
  data() {
    return {
      visible: false,
      dataForm: {
        id: '',
        catalogId: '',
        code: '',
        name: '',
        liable: '',
        pages: '',
        fileDate: '',
        metaValList: []
      }
    }
  },
  components: {
    ArchiveMetaViewer
  },
  computed: {
    dataRule() {
      return {
        code: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        name: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
      }
    }
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo() {
      this.$http.get('/mps/archivePostDir/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {
        // do nothing
      })
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        this.$http[!this.dataForm.id ? 'post' : 'put']('/mps/archivePostDir/', this.dataForm).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.visible = false
              // 返回 相关信息
              let mainId = this.dataForm.catalogId
              let id = this.dataForm.id ? this.dataForm.id : res.data
              let name = this.dataForm.name
              let type = !this.dataForm.id ? 'add' : 'update' // 类型 新增/修改
              this.$emit('refreshDataList', {type, id, name, mainId})
            }
          })
        }).catch(() => {
          // do nothing
        })
      })
    }, 1000, {'leading': true, 'trailing': false})
  }
}
</script>