<template>
  <div>
    <el-divider content-position="left">基本信息</el-divider>
    <el-descriptions :column="3" border>
      <el-descriptions-item label="图文编号">{{ dataForm.code }}</el-descriptions-item>
      <el-descriptions-item label="文件题名" :span="2">{{ dataForm.name }}</el-descriptions-item>
      <el-descriptions-item label="责任者">{{ dataForm.liable }}</el-descriptions-item>
      <el-descriptions-item label="页次">{{ dataForm.pages }}</el-descriptions-item>
      <el-descriptions-item label="文件形成日期">{{ dataForm.fileDate }}</el-descriptions-item>
    </el-descriptions>
    <el-divider content-position="left">著录信息</el-divider>
    <archive-meta-viewer :dataList="dataForm.metaValList" readonly></archive-meta-viewer>
  </div>
</template>

<script>
import ArchiveMetaViewer from "@/components/archive-meta-viewer"

export default {
  data() {
    return {
      dataForm: {
        id: '',
        catalogId: '',
        code: '',
        name: '',
        liable: '',
        pages: '',
        fileDate: '',
        metaValList: []
      }
    }
  },
  components: {
    ArchiveMetaViewer
  },
  methods: {
    init() {
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo() {
      this.$http.get('/mps/archivePostDir/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {
        // do nothing
      })
    },
  }
}
</script>