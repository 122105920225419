<template>
  <div>
    <el-button v-if="!readonly" icon="el-icon-plus" type="text" @click="openNewSchemeModal()" size="mini" style="margin-bottom: 5px">选择著录方案</el-button>
    <vxe-table
        show-overflow
        ref="table"
        show-header
        border
        :data="dataList"
        :edit-config="{enabled: !this.readonly,trigger: 'click', mode: 'row'}"
    >
      <vxe-column field="metaName" title="元数据名称"></vxe-column>
      <vxe-column field="val" title="著录信息" :edit-render="{name: '$input'}"></vxe-column>
      <vxe-column title="操作" width="50" align="center" fixed="right" :visible="!readonly">
        <template #default="{rowIndex}">
          <i class="el-icon-close" style="color: red;font-size: 18px;cursor: pointer;" @click="delTableRow(rowIndex)"></i>
        </template>
      </vxe-column>
    </vxe-table>
    <vxe-modal :loading="schemeModalLoading" v-model="schemeModalVisible" size="small" :width="700" type="confirm" title="选择著录方案" show-footer 
      @confirm="onSchemeModalConfirm">
      <template #default>
        <vxe-select v-model="schemeSelectedId" placeholder="请选择著录方案" @change="onSchemeChange">
          <vxe-option v-for="scheme in schemeList" :key="scheme.id" :value="scheme.id" :label="scheme.name"></vxe-option>
        </vxe-select>
        <vxe-table
            :loading="modalTableLoading"
            ref="modalTable"
            show-overflow
            show-header
            border
            :data="schemeMxList"
            :edit-config="{trigger: 'click', mode: 'row'}"
            style="margin-top: 5px"
        >
          <vxe-column field="metaName" title="元数据名称" width="250"></vxe-column>
          <vxe-column field="val" title="著录信息" class-name="cell-underline" :edit-render="{name: '$input'}"></vxe-column>
          <vxe-column title="操作" width="50" align="center" fixed="right">
            <template #default="{rowIndex}">
              <i class="el-icon-close" style="color: red;font-size: 18px;cursor: pointer;" @click="delModalTableRow(rowIndex)"></i>
            </template>
          </vxe-column>
        </vxe-table>
      </template>
    </vxe-modal>
  </div>
</template>
<script>
import {isStringEmpty} from "@/utils/validate";

export default {
  name: 'ArchiveMetaViewer',
  data () {
    return {
      schemeModalVisible: false,
      schemeModalLoading: false,
      modalTableLoading: false,
      schemeList: [],
      schemeSelectedId: '',
      schemeMxList: []
    }
  },
  props: {
    dataList: {
      type: Array,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: true,
    }
  },
  methods: {
    openNewSchemeModal() {
      this.schemeModalVisible = true
      // 若著录方案选择弹窗未加载过著录方案，则加载
      if (this.schemeList.length === 0) {
        this.schemeModalLoading = true
        this.$http.get('/mps/archiveScheme/list').then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.schemeList = res.data
        }).catch(() => {}).finally(() => {
          this.schemeModalLoading = false
        })
      }
    },
    // 选择著录方案时，加载该著录方案下的元数据
    onSchemeChange({value: schemeId}) {
      this.modalTableLoading = true
      this.$http.get(`/mps/archiveScheme/${schemeId}/mx`).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.schemeMxList = res.data
        this.modalTableLoading = false
      }).catch(() => {})
    },
    // 点击确定按钮时，将选择好的著录方案元数据添加到dataList中
    // 去重逻辑：如果dataList中存在该元数据，则更新其值（若新值为空，则不更新），否则添加
    onSchemeModalConfirm() {
      for (let schemeMx of this.schemeMxList) {
        let metaId = schemeMx.metaId
        let index = this.dataList.findIndex(item => item.metaId == metaId)
        if (index == -1) {
          this.dataList.push(schemeMx)
        } else {
          if (!isStringEmpty(schemeMx.val)) {
            this.dataList[index] = schemeMx
          }
        }
      }
      this.dataList = this.dataList.sort((a, b) => a.sortNo - b.sortNo)
      this.schemeMxList = []
      this.schemeSelectedId = ''
    },
    delTableRow(rowIndex) {
      this.dataList.splice(rowIndex, 1)
    },
    delModalTableRow(rowIndex) {
      this.schemeMxList.splice(rowIndex, 1)
    }
  }
}
</script>
