<template>
  <div>
    <!--<el-divider content-position="left">著录信息</el-divider>-->
    <archive-meta-viewer :dataList="dataForm.metaValList" readonly></archive-meta-viewer>
  </div>
</template>

<script>
import ArchiveMetaViewer from "@/components/archive-meta-viewer";

export default {
  data() {
    return {
      dataForm: {
        id: '',
        metaValList: []
      }
    }
  },
  components: {
    ArchiveMetaViewer
  },
  methods: {
    init() {
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo() {
      this.$http.get('/mps/archiveOutline/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {
        // do nothing
      })
    },
  }
}
</script>
