<template>
  <my-upload v-if="refId" ref="uploader" :refId="refId" :view="!canEdit" :tableMaxHeight="height" :deleteUrl="deleteUrl"></my-upload>
</template>

<script>
// 卷内文件列表
export default {
  data() {
    return {
      refId: '',
      canEdit: false,
      deleteUrl: '/oss/file'
    }
  },
  props: {
    height: Number
  },
  methods: {}
}
</script>
