<template>
  <el-dialog v-dialog-drag :visible.sync="visible" :title="!dataForm.id ? '新增 案卷' : '修改 案卷'" top="5vh" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <el-divider content-position="left">基本信息</el-divider>
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="案卷题名" prop="name">
            <el-input v-model="dataForm.name" placeholder="案卷题名"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="案卷档号" prop="code">
            <el-input v-model="dataForm.code" placeholder="案卷档号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="立卷人" prop="filer">
            <el-input v-model="dataForm.filer" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="立卷日期" prop="filingDate">
            <el-date-picker type="date" placeholder="立卷日期" value-format="yyyy-MM-dd" v-model="dataForm.filingDate" style="width: 100%;" :clearable="!dataForm.id"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="件数" prop="cases">
            <el-input v-model="dataForm.cases" placeholder="件数"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="总页数" prop="pages">
            <el-input v-model="dataForm.pages" placeholder="总页数"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="保管期限" prop="period">
            <dict-select v-model="dataForm.period" dict-type="archive_period" placeholder="请选择" style="width: 100%;"></dict-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="保管密级" prop="secret">
            <dict-select v-model="dataForm.secret" dict-type="archive_secret" placeholder="请选择" style="width: 100%;"></dict-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注" prop="remark">
            <el-input type="textarea" v-model="dataForm.remark" :autosize="{ minRows: 3,maxRows:10 }"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="dataForm.id">
          <el-form-item label="排序序号" prop="sortNo">
            <el-input-number v-model="dataForm.sortNo" controls-position="right" :min="0"></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-divider content-position="left">著录信息</el-divider>
    <archive-meta-viewer :dataList="dataForm.metaValList" :readonly="false"></archive-meta-viewer>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'
import ArchiveMetaViewer from "@/components/archive-meta-viewer"

export default {
  data() {
    return {
      visible: false,
      dataForm: {
        id: '',
        outlineId: '',
        code: '',
        name: '',
        deptId: '',
        filingDate: '',
        cases: '',
        pages: '',
        period: '',
        secret: '',
        remark: '',
        sortNo: '',
        filer: '',
        metaValList: []
      }
    }
  },
  computed: {
    dataRule() {
      return {
        code: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
        name: [
          {required: true, message: this.$t('validate.required'), trigger: 'blur'}
        ],
      }
    }
  },
  components: {
    ArchiveMetaViewer
  },
  methods: {
    init() {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        } else {
          // 立卷人
          this.dataForm.filer = this.$store.state.user.realName
        }
      })
    },
    // 获取信息
    getInfo() {
      this.$http.get('/mps/archivePreCatalog/' + this.dataForm.id).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {
        // do nothing
      })
    },
    // 表单提交
    dataFormSubmitHandle: debounce(function () {
      this.$refs['dataForm'].validate((valid) => {
        if (!valid) {
          return false
        }
        this.$http[!this.dataForm.id ? 'post' : 'put']('/mps/archivePreCatalog/', this.dataForm).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.visible = false
              // 返回 相关信息
              let mainId = this.dataForm.outlineId
              let id = this.dataForm.id ? this.dataForm.id : res.data
              let name = this.dataForm.name
              let type = !this.dataForm.id ? 'add' : 'update' // 类型 新增/修改
              this.$emit('refreshDataList', {type, id, name, mainId})
            }
          })
        }).catch(() => {
          // do nothing
        })
      })
    }, 1000, {'leading': true, 'trailing': false})
  }
}
</script>