<template>
  <el-dialog v-dialog-drag width="640px" :visible.sync="visible" title="关联质检资料" top="1vh" :close-on-click-modal="false" :close-on-press-escape="false" v-on="$listeners">
    <contract-cascader include-sub @change="contractChange"></contract-cascader>
    <vxe-table ref="xTree" resizable :loading="treeLoading" show-overflow
               :max-height="maxHeight" border="none" :show-header="false" :tooltip-config="{theme: 'light'}"
               :row-config="rowConfig" :tree-config="treeConfig" :data="treeData" :checkbox-config="checkboxConfig"
               cell-class-name="pointer-cursor">
      <vxe-column type="checkbox" field="name" tree-node>
        <template v-slot="{row}">
          <span v-if="row.isQcFile" style="color: #0e9d6a">{{ row.name }}</span>
          <span v-else style="color: rgb(0 0 0 / 70%);">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ row.name }}</span>
        </template>
      </vxe-column>
    </vxe-table>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="submit()">{{ $t('confirm') }}</el-button>
    </template>
  </el-dialog>
</template>

<script>

export default {
  data() {
    return {
      visible: false,
      treeLoading: false,
      treeData: [],
      maxHeight: 500,
      rowConfig: {
        isCurrent: true,
        isHover: true,
        useKey: true,
        height: 27,
        keyField: 'id'
      },
      treeConfig: {
        showLine: true,
        lazy: true,
        iconOpen: 'vxe-icon-square-minus',
        iconClose: 'vxe-icon-square-plus',
        parentField: 'pid',
        hasChild: 'hasChildren',
        trigger: 'row',
        loadMethod: this.loadChildren
      },
      checkboxConfig: {
        showHeader: false,
        checkStrictly: true,
        visibleMethod: ({row}) => row.isQcFile,
        checkMethod: ({row}) => row.isQcFile,
        trigger: 'row',
      },
      dataForm: {
        contractId: '',
        subcontractorId: '',
        wbsId: '',
        workstageId: '',
      }
    }
  },
  mounted() {
    this.maxHeight = window.innerHeight - 220
  },
  methods: {
    init() {
      this.visible = true
    },
    // 项目、标段切换事件
    contractChange(data) {
      this.treeData = []
      this.dataForm.contractId = data.contractId
      this.dataForm.subcontractorId = data.subcontractorId
      this.dataForm.wbsId = ''
      this.dataForm.workstageId = ''
      this.initTree()
    },
    initTree() {
      this.treeLoading = true
      this.$nextTick(() => {
        this.$http.get('/mps/archivePreDir/wbs/children',
            {
              params: {
                wbsIsLeaf: false,
                wbsPid: '0',
                ...this.dataForm
              }
            }
        ).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          res.data.forEach(item => {
            item.hasChildren = true
            item.isWbs = true
          })
          this.treeData = res.data
        }).catch(() => {
          this.$message.error('出错了')
        }).finally(() => {
          this.treeLoading = false
        })
      })
    },
    loadChildren({row}) {
      return new Promise((resolve, reject) => {
        // 1.如果是wbs
        if (row.isWbs) {
          this.$http.get('/mps/archivePreDir/wbs/children',
              {
                params: {
                  wbsPid: row.wbsId,
                  wbsIsLeaf: row.wbsIsLeaf + '',
                  ...this.dataForm
                }
              }
          ).then(({data: res1}) => {
            if (res1.code !== 0) {
              this.$message.error(res1.msg)
              reject()
            }
            this.$http.get('/mps/archivePreDir/qcInspectionData/list',
                {
                  params: {
                    wbsId: row.wbsId,
                    workstageId: row.workstageId,
                  }
                }
            ).then(({data: res2}) => {
              if (res2.code !== 0) {
                this.$message.error(res2.msg)
                reject()
              }
              let result = []
              // 1.1.如果是非叶节点，查询结果为质检资料、下级wbs节点
              // 1.2.如果是叶节点，查询结果为质检资料、下级工序节点
              if (!row.wbsIsLeaf) {
                res1.data.forEach(item => {
                  item.hasChildren = true
                  item.isWbs = true
                })
              } else {
                res1.data.forEach(item => {
                  item.hasChildren = true
                  item.isWorkstage = true
                })
              }
              res2.data.forEach(item => {
                item.hasChildren = false
                item.isQcFile = true
              })
              result.push(...res1.data)
              result.push(...res2.data)
              if (result.length == 0) {
                row.hasChildren = false
              }
              resolve(result);
            })
          })
        } else if (row.isWorkstage) {
          // 2.如果是工序，则查询其下的质检资料
          this.$http.get('/mps/archivePreDir/qcInspectionData/list',
              {
                params: {
                  wbsId: row.wbsId,
                  workstageId: row.workstageId,
                }
              }
          ).then(({data: res}) => {
            if (res.code !== 0) {
              this.$message.error(res.msg)
              reject()
            }
            res.data.forEach(item => {
              item.hasChildren = false
              item.isQcFile = true
            })
            if (res.data.length == 0) {
              row.hasChildren = false
            }
            resolve(res.data);
          })
        }
      })
    },
    submit() {
      let result = this.$refs.xTree.getCheckboxRecords().map(item => item.pdfId)
      this.visible = false
      this.$emit('submit', result)
    }
  }
}
</script>