<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__archiveppost">
      <my-container :leftWidth="350">
        <template v-slot:left>
          <div class="tree-container">
            <vxe-table ref="xtree" resizable :loading="treeLoading" show-overflow
                       height="auto" border="none" :show-header="false" :tooltip-config="{theme: 'light'}"
                       :row-config="rowConfig" :tree-config="treeConfig" :data="treeData" :menu-config="treeMenu"
                       @menu-click="contextMenuClick"
                       cell-class-name="pointer-cursor"
                       @cell-click="treeCellClick">
              <vxe-column field="name" width="700" tree-node>
                <template v-slot="{row}">
                  <span v-if="row.archiveType == archiveType.catalog" style="color: #0e9d6a">{{ row.name }}</span>
                  <span v-else-if="row.archiveType == archiveType.dir" style="color: #c07a17">{{ row.name }}</span>
                  <span v-else>{{ row.name }}</span>
                </template>
              </vxe-column>
            </vxe-table>
          </div>
        </template>
        <template v-slot:right>
          <el-tabs v-model="tabActiveName" type="border-card" :style="{height: rightTabsHeight + 'px'}">
            <el-tab-pane label="基本内容" name="tab1" key="tab1" v-if="dataForm.archiveType">
              <div v-if="dataForm.archiveType==archiveType.catalog" style="margin-left: 10px">
                <el-button v-if="canEditCatalog()" type="primary" icon="el-icon-edit-outline" size="mini" @click="openUpdateDioalog(dataForm)" :disabled="!canEditCatalog()">修改</el-button>
                <catalog-view ref="catalogView" :style="{height: tabContentHeight + 'px', 'overflow-y': 'auto'}"></catalog-view>
              </div>
              <div v-if="dataForm.archiveType==archiveType.dir" style="margin-left: 10px">
                <el-button v-if="canEditDir()" type="primary" icon="el-icon-edit-outline" size="mini" @click="openUpdateDioalog(dataForm)" :disabled="!canEditDir()">修改</el-button>
                <dir-view ref="dirView" :style="{height: tabContentHeight + 'px', 'overflow-y': 'auto'}"></dir-view>
              </div>
              <div v-if="dataForm.archiveType==archiveType.outline" style="margin-left: 10px">
                <outline-view ref="outlineView" :style="{height: rightTabsHeight + 'px', 'overflow-y': 'auto'}"></outline-view>
              </div>
            </el-tab-pane>
            <el-tab-pane label="关联文件" name="tab2" key="tab2" v-if="dataForm.archiveType==archiveType.dir" style="margin-left: 10px">
              <archive-file-list ref="archiveFileList" :height="fileTableHeight"></archive-file-list>
            </el-tab-pane>
          </el-tabs>
        </template>
      </my-container>
      <!-- 案卷目录 新增、修改 -->
      <catalog-add-or-update v-if="catalogAddOrUpdateVisible" ref="catalogAddOrUpdate" @refreshDataList="refreshTreeNode" @close="closeDialogHandle"></catalog-add-or-update>
      <!-- 卷内目录 新增、修改 -->
      <dir-add-or-update v-if="dirAddOrUpdateVisible" ref="dirAddOrUpdate" @refreshDataList="refreshTreeNode" @close="closeDialogHandle"></dir-add-or-update>
    </div>
  </el-card>
</template>
<script>
// 组卷管理 （包含案卷目录、卷内目录的管理）
import CatalogAddOrUpdate from './archivepost-catalog-add-or-update'
import DirAddOrUpdate from './archivepost-dir-add-or-update'
import CatalogView from './archivepost-catalog-view'
import DirView from './archivepost-dir-view'
import ArchiveFileList from './archive-file-list'
import OutlineView from "./archivepost-outline-view.vue";
import mixinViewModule from "@/mixins/view-module";

export default {
  mixins: [mixinViewModule],
  components: {
    CatalogAddOrUpdate, DirAddOrUpdate,
    CatalogView, DirView, ArchiveFileList, OutlineView,
  },
  data() {
    return {
      mixinViewModuleOptions: {
        createdIsNeed: false,
        deleteURL: '',
        deleteSuccessCallback: this.deleteSuccessCallback,
        deleteWithRelatedFiles: false
      },
      dataForm: {}, // 当前节点数据 =row
      treeLoading: false,
      treeData: [],
      rowConfig: {
        isCurrent: true,
        isHover: true,
        useKey: true,
        height: 27,
        keyField: 'id'
      },
      treeConfig: {
        transform: true,// 自动将列表转成树结构
        showLine: true,
        lazy: true,
        iconOpen: 'vxe-icon-square-minus',
        iconClose: 'vxe-icon-square-plus',
        parentField: 'pid',
        hasChild: 'hasChildren',
        loadMethod: this.loadChildren
      },
      treeMenu: {
        body: {
          options: [
            [
              {code: 'addCatalog', name: '新增案卷', prefixIcon: 'vxe-icon-add', visible: false},
              {code: 'addDir', name: '新增卷内文件', prefixIcon: 'vxe-icon-add', visible: false},
              {code: 'update', name: '修改', prefixIcon: 'vxe-icon-edit', visible: false},
              {code: 'delete', name: '删除', prefixIcon: 'vxe-icon-delete', visible: false},
            ]
          ]
        },
        visibleMethod: this.visibleMenu
      },
      archiveType: { // 节点类型
        outline: 1, // 分类大纲
        catalog: 2, // 案卷目录
        dir: 3 // 卷内目录
      },
      outlineDataList: [], // 分类大纲的数据集合
      catalogAddOrUpdateVisible: false,
      dirAddOrUpdateVisible: false,
      tabActiveName: 'tab2',
      rightTabsHeight: 550,
      tabContentHeight: 485,
      fileTableHeight: 385,
    }
  },
  mounted() {
    this.rightTabsHeight = window.innerHeight - 140
    this.tabContentHeight = this.rightTabsHeight - 65
    this.fileTableHeight = this.rightTabsHeight - 165
  },
  created() {
    this.getTreeData()
  },
  methods: {
    getTreeData() {
      this.treeLoading = true
      this.$http.get(`/mps/archiveOutline/post/treeList`).then(({data: res}) => {
        if (res.code != 0) {
          return this.$message.error(res.msg)
        }
        this.outlineDataList = res.data
        this.treeData = res.data.filter(data => data.pid == 0)
        if (this.treeData && this.treeData.length > 0) {
          this.$nextTick(() => {
            // 默认展开第一个节点、选中第一个节点
            this.treeAutoExpandAndClick(this.treeData[0].id)
          })
        }
      }).catch(() => {
        this.$message.error('加载出错')
      }).finally(() => {
        this.treeLoading = false
      })
    },
    // 左侧树节点的点击事件
    treeCellClick({row, triggerTreeNode}) {
      if (!triggerTreeNode) {
        this.$refs.xtree.setTreeExpand(row, true)
        this.dataForm = {...row}
        this.$nextTick(() => {
          // 刷新右侧的数据
          this.fetchRightTabData()
        })
      }
    },

    // 异步加载案卷目录、卷内目录
    loadChildren({row}) {
      // 加载分类大纲
      if (row.archiveType == this.archiveType.outline) {
        if (row.outlineLeaf) {
          // 加载案卷目录 列表
          return this.findCatalogList(row.id)
        } else {
          // 加载分类大纲下的子节点
          return new Promise(resolve => {
            let childrenData = this.outlineDataList.filter(data => data.pid == row.id)
            resolve(childrenData)
          })
        }
      } else if (row.archiveType == this.archiveType.catalog) {
        // 加载卷内目录
        return this.findDirList(row.id)
      }
    },
    // 获取案卷目录列表
    findCatalogList(outlineId) {
      return new Promise((resolve, reject) => {
        this.$http.get(
            '/mps/archivePostCatalog/list',
            {
              params: {
                outlineId: outlineId
              }
            }
        ).then(({data: res}) => {
          if (res.code !== 0) {
            reject()
            return this.$message.error(res.msg)
          }
          if (res.data != null && res.data.length > 0) {
            res.data.forEach(item => {
              // 案卷目录数据 拼接树结构
              item.pid = outlineId
              item.archiveType = this.archiveType.catalog // 当前节点类型
              item.outlineLeaf = false // 是否是分类大纲的叶子节点
              item.hasChildren = item.hasDir // 是否有子节点
            })
          }
          resolve(res.data)
        }).catch(() => {
          reject()
        })
      })
    },

    // 获取卷内目录列表
    findDirList(catalogId) {
      return new Promise((resolve, reject) => {
        this.$http.get(
            '/mps/archivePostDir/list',
            {
              params: {
                catalogId: catalogId
              }
            }
        ).then(({data: res}) => {
          if (res.code !== 0) {
            reject()
            return this.$message.error(res.msg)
          }
          if (res.data != null && res.data.length > 0) {
            res.data.forEach(item => {
              // 案卷目录数据 拼接树结构
              item.pid = catalogId
              item.archiveType = this.archiveType.dir // 当前节点类型
              item.outlineLeaf = false // 是否是分类大纲的叶子节点
              item.hasChildren = false // 是否有子节点
            })
          }
          resolve(res.data)
        }).catch(() => {
          reject()
        })
      })
    },

    // 显示右键菜单选项
    visibleMenu({type, options, row}) {
      // 选中节点后，显示右键菜单项
      if (type == 'body' && row) {
        options.forEach(list => {
          list.forEach(item => {
            // 先隐藏右键菜单项, 然后再根据权限设置是否显示
            item.visible = false
            if (row.archiveType == this.archiveType.outline) { // 点击的是分类大纲节点
              if (row.outlineLeaf) {
                if (this.canEditCatalog()) {
                  // 新增案卷目录
                  if (['addCatalog'].includes(item.code)) {
                    item.visible = true
                  }
                }
              }
            } else if (row.archiveType == this.archiveType.catalog) { // 点击的是案卷目录节点
              if (this.canEditDir()) {
                if (['addDir'].includes(item.code)) {
                  item.visible = true
                }
              }
              if (this.canEditCatalog()) {
                if (['update'].includes(item.code)) {
                  item.visible = true
                }
              }
              if (this.canDeleteCatalog()) {
                if (['delete'].includes(item.code)) {
                  item.visible = true
                }
              }
            } else if (row.archiveType == this.archiveType.dir) { // 点击的是卷内目录节点
              if (this.canEditDir()) {
                if (['update'].includes(item.code)) {
                  item.visible = true
                }
              }
              if (this.canDeleteDir()) {
                if (['delete'].includes(item.code)) {
                  item.visible = true
                }
              }
            }
          })
        })
      }
      return true
    },

    // 点击右键菜单项
    contextMenuClick({menu, type, row}) {
      if (type == 'body' && row) {
        switch (menu.code) {
          case 'addCatalog':
            this.openAddCatalog(row.id)
            break
          case 'addDir':
            this.openAddDir(row.id)
            break
          case 'update':
            this.openUpdateDioalog(row)
            break
          case 'delete':
            this.deleteOne(row)
            break
        }
      }
    },

    // 新增案卷目录
    openAddCatalog(id) {
      this.catalogAddOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.catalogAddOrUpdate.dataForm.id = ''
        this.$refs.catalogAddOrUpdate.dataForm.outlineId = id
        this.$refs.catalogAddOrUpdate.init()
      })
    },

    // 新增卷内目录
    openAddDir(id) {
      this.dirAddOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.dirAddOrUpdate.dataForm.id = ''
        this.$refs.dirAddOrUpdate.dataForm.catalogId = id
        this.$refs.dirAddOrUpdate.init()
      })
    },
    // 修改案卷目录、卷内目录
    openUpdateDioalog(row) {
      // 修改案卷目录
      if (row.archiveType == this.archiveType.catalog) {
        this.catalogAddOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.catalogAddOrUpdate.dataForm.id = row.id
          this.$refs.catalogAddOrUpdate.dataForm.outlineId = row.pid
          this.$refs.catalogAddOrUpdate.init()
        })
      }
      // 修改卷内目录
      if (row.archiveType == this.archiveType.dir) {
        this.dirAddOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.dirAddOrUpdate.dataForm.id = row.id
          this.$refs.dirAddOrUpdate.dataForm.catalogId = row.pid
          this.$refs.dirAddOrUpdate.init()
        })
      }
    },
    deleteSuccessCallback({row}) {
      this.treeAutoExpandAndClick(row.pid)
    },
    // 删除案卷目录、卷内目录
    deleteOne(row) {
      if (row.archiveType == this.archiveType.catalog) {
        // 删除案卷目录
        this.mixinViewModuleOptions.deleteURL = 'mps/archivePostCatalog'
      } else if (row.archiveType == this.archiveType.dir) {
        // 删除卷内目录
        this.mixinViewModuleOptions.deleteURL = 'mps/archivePostDir'
      } else {
        return
      }
      this.deleteHandle(row.id, {deleteSuccessCallbackArgs: {row}, autoQuery: false})
    },
    // 刷新案卷目录、卷内目录
    refreshTreeNode(item) {
      if (item.type == 'add') {
        let currentRow = this.$refs.xtree.getRowById(item.mainId)
        currentRow.hasChildren = true
        this.treeAutoExpandAndClick(item.mainId)
      } else if (item.type == 'update') {
        let row = this.$refs.xtree.getRowById(item.id)
        row.name = item.name
        if (item.id == this.dataForm.id) {
          this.treeAutoExpandAndClick(item.id)
        }
      }
    },

    // 关闭弹窗
    closeDialogHandle() {
      this.catalogAddOrUpdateVisible = false
      this.dirAddOrUpdateVisible = false
    },

    // 左侧树展开至指定节点处，并模拟点击选中
    treeAutoExpandAndClick(rowId) {
      // 找到当前行，重新加载节点,将其选中并触发点击事件
      let currentRow = this.$refs.xtree.getRowById(rowId)
      this.$refs.xtree.clearTreeExpandLoaded(currentRow)
      this.$refs.xtree.setTreeExpand(currentRow)
      this.$refs.xtree.setCurrentRow(currentRow)
      this.treeCellClick({row: currentRow, triggerTreeNode: false})
    },

    // 重新获取右侧tab各页签中的数据
    fetchRightTabData() {
      this.tabActiveName = 'tab1'
      this.fetchBasicInfo()
      this.fetchFileList()
    },
    // 加载右侧案卷目录、卷内目录、分类大纲的基础信息
    fetchBasicInfo() {
      if (this.dataForm.archiveType == this.archiveType.outline) {
        this.$refs.outlineView.dataForm.id = this.dataForm.id
        this.$refs.outlineView.init()
      }
      if (this.dataForm.archiveType == this.archiveType.catalog) {
        this.$refs.catalogView.dataForm.id = this.dataForm.id
        this.$refs.catalogView.dataForm.outlineId = this.dataForm.pid
        this.$refs.catalogView.init()
      }
      if (this.dataForm.archiveType == this.archiveType.dir) {
        this.$refs.dirView.dataForm.id = this.dataForm.id
        this.$refs.dirView.dataForm.catalogId = this.dataForm.pid
        this.$refs.dirView.init()
      }
    },
    fetchFileList() {
      if (this.dataForm.archiveType == this.archiveType.dir) {
        this.$refs.archiveFileList.canEdit = this.canEditDir()
        this.$refs.archiveFileList.refId = this.dataForm.id
      }
    },
    canEditCatalog() {
      return this.$hasPermission('mps:archivepostcatalog:edit')
    },
    canEditDir() {
      return this.$hasPermission('mps:archivepostdir:edit')
    },
    canDeleteCatalog() {
      return this.$hasPermission('mps:archivepostcatalog:delete')
    },
    canDeleteDir() {
      return this.$hasPermission('mps:archivepostdir:delete')
    }
  }
}
</script>

<style scoped>
.tree-container {
  width: 100%;
  overflow: auto;
  height: calc(100vh - 140px);
}
</style>