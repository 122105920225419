<template>
  <el-dialog :visible.sync="visible" title="查看" v-on="$listeners">
    <el-divider content-position="left">基本信息</el-divider>
    <el-form :model="dataForm" ref="dataForm" label-width="auto" size="small">
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item label="上级节点" prop="pid">
            <archiveoutline-parent-select v-model="dataForm.pid" :disabledId="dataForm.id" readonly></archiveoutline-parent-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="目录名称" prop="name">
            <el-input v-model="dataForm.name" placeholder="目录名称" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="目录代码" prop="code">
            <el-input v-model="dataForm.code" placeholder="目录代码" readonly></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="sortNo" label="排序序号">
            <el-input-number v-model="dataForm.sortNo" controls-position="right" :min="0" readonly></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-divider content-position="left">著录信息</el-divider>
    <archive-meta-viewer :dataList="dataForm.metaValList" readonly></archive-meta-viewer>
    <template slot="footer">
      <el-button @click="visible = false">关闭</el-button>
    </template>
  </el-dialog>
</template>

<script>
import ArchiveoutlineParentSelect from "@/views/modules/mps/archiveoutline-parent-select"
import ArchiveMetaViewer from "@/components/archive-meta-viewer";

export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: '',
        pid: '',
        pids: '',
        pnames: '',
        code: '',
        name: '',
        leaf: '',
        sortNo: '',
        tenantCode: '',
        creator: '',
        updater: '',
        createDate: '',
        updateDate: '',
        metaValList: []
      },
    }
  },
  components: {
    ArchiveoutlineParentSelect,
    ArchiveMetaViewer
  },
  methods: {
    init () {
      this.visible = true
      this.$nextTick(() => {
        if (this.dataForm.id) {
          this.getInfo()
        }
      })
    },
    // 获取信息
    getInfo () {
      this.$http.get('/mps/archiveOutline/' + this.dataForm.id).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataForm = {
          ...this.dataForm,
          ...res.data
        }
      }).catch(() => {})
    },
  }
}
</script>
